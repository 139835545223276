import { Component, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { DeliveryMethod } from 'src/app/shared/models/checkout.model';
import { DeliveryMethodsAvailable, SaleTransaction } from 'src/app/shared/models/transactions/sale-transaction.model';
import { TdcInfo, User } from 'src/app/shared/models/user.model';
import { AdyenDropInComponent } from '../adyen/adyen-drop-in/adyen-drop-in.component';
import { SweetAlertOptions } from 'sweetalert2';
import { MembershipTransaction } from 'src/app/shared/models/transactions/membership-transaction.model';
import { MembershipTransactionService } from 'src/app/core/services/override/checkout/membership-transaction.service';
import { PackageTransaction } from 'src/app/shared/models/transactions/package-transaction.model';
import { ExchangeTransaction } from 'src/app/shared/models/transactions/exchange-transaction.model';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { ReservationTransaction } from 'src/app/shared/models/transactions/reservation-transaction.model';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent implements OnInit {

  constructor(
    private checkoutService: CheckoutService<ExchangeTransaction | MembershipTransaction | PackageTransaction | ReservationTransaction | SaleTransaction>
  ){}

  @ViewChild('checkoutModal')
  checkoutModal!: SwalComponent;

  @ViewChild('adyen', { read: ViewContainerRef })
  adyenModal!:    ViewContainerRef;

  /**
   * SERVICES
   */
  private authService:      AuthService       = inject(AuthService);
  private modalService:     ModalService      = inject(ModalService);
  private router:           Router            = inject(Router);
  private params:           Params            = inject(ActivatedRoute).snapshot.queryParams;  
  private membership:       MembershipTransactionService | undefined = this.type === 'membership' ? inject(MembershipTransactionService) : undefined;

  protected user!:               User;
  protected transactions!:       (SaleTransaction | MembershipTransaction | PackageTransaction | ExchangeTransaction | ReservationTransaction )[];
  protected selectedDelivery!:   DeliveryMethodsAvailable;
  protected swalTarget:          SwalPortalTargets = inject(SwalPortalTargets)

  public templateData: any = {
    title:  'Your selection is now held, please complete your transaction before the timer runs out.',
    text:   'Choose your delivery method and click ‘Checkout’. You will then be redirected to an external website to process your payment.',
  }

  public adressForm: {adress: string , city: string ,countryCode: string , countryName: string, zipCode: string } = {
    adress: '',
    city: '',
    countryCode: '',
    countryName: '',
    zipCode: ''
  }

  public get type(): string {
    return this.params['type'];
  }

  public get transactionId(): number {
    return parseInt(this.params['transaction'])
  }

  public get masterSale(): SaleTransaction | MembershipTransaction {
    return this.transactions[0];
  }

  public get deliveryMethods(): DeliveryMethodsAvailable[] {
    return this.masterSale.delivery_methods_available;
  }

  public get ticketQuantity(): string {

    return window.innerWidth >= 1024 ?  
      `My Tickets (${this.transactions.length})` :
      `See My Tickets (${this.transactions.length})`

  }

  public get totalAmount(): number {
    return this.masterSale.total_price;
  }

  public get deliveryAmount(): number {
    return this.masterSale.delivery_methods_available[0].price
  }

  public get isAdressFormFull(): boolean {
    if(this.deliveryMethods[0].type !== 'EXTERNAL_TICKETS_AT_HOME' || (this.selectedDelivery  && this.selectedDelivery.type !== 'EXTERNAL_TICKETS_AT_HOME')) {
        let voidItems: string[] = Object.values(this.adressForm).filter(item => item === '');
        return voidItems.length ? true : false;
    }else{
      return false;
    }
  }

  ngOnInit(): void {
    this.checkUserTransactions(this.type);
  }

  public addTransaction(): void {
    const params: Params = { transaction: this.masterSale.id };
    this.router.navigate([''], {queryParams: params});
  }

  public launchModal(): void {
    const title: string = 'Continue?'
    const text:  string = `
      <div class="text-left max-h-80 text-sm overflow-y-scroll">
        <p>
          I hereby confirm that I have read the Chelsea Football Club’s Conditions Of Issue which are available for inspection at 
          <a class=" text-secondary" href="https://assets.ctfassets.net/d4h4t9hbh431/2qZIGurMcgmWLlbGdG91I/d3b70bc5d66e6188ec833e258ad1d061/Conditions_of_Issue_-_2022-2023.pdf" target="_blank">Conditions of Issue</a>'
          and posted at the club’s offices and agree to abide by them.'
        </p>
          <br>
        <p>
          In addition, if I am purchasing Club Chelsea tickets, I hereby confirm that I have also read the Club Chelsea Ticket Terms and Conditions which are available for inspection at
          <a class=" text-secondary" href="https://www.chelseafc.com/en/club-chelsea-ticket-terms-and-conditions" target="_blank">www.chelseafc.com/en/club-chelsea-ticket-terms-and-conditions</a>'
          and posted at the club’s offices and agree to abide by them.'
        </p>
        <br>
        <p>
          I understand that my season ticket/membership/Club Chelsea ticket/match ticket may be withdrawn, revoked or terminated asprovided therein. Chelsea FC will use your personal data in accordance with its privacy policy,
          which you can read at <a class=" text-secondary" href="https://www.chelseafc.com/en/footer/privacy-policy" target="_blank">www.chelseafc.com/en/footer/privacy-policy</a>.
        </p>
        <br>
        <p>
          If I am purchasing Legends of Europe tickets, I hereby confirm that I have read: (ï) the Legends Match Ticketing Policy, which is available for inspection at 
          <a class=" text-secondary" href="https://www.chelseafc.com/en/legends-match-ticketing-policy" target="_blank">www.chelseafc.com/en/legends-match-ticketing-policy</a>' and (ii) the Legends Match Ticket Conditions of Issue, 
          which is available for inspection at <a href="https://www.chelseafc.com/en/legends-match-conditions-of-issue" target="_blank">www.chelseafc.com/en/legends-match-conditions-of-issue</a>', 
          and agree to abide by them. Both the Legends Match Ticketing Policy and the Legends Match Ticket Conditions of Issue are posted at the club’s offices'
        </p>
        <br>
        <div>
          <p class="dark:text-secondary">
            <b>
              NOTE
            </b>
          </p>
          <p class="text-red-500 text-sm mt-2 font-medium">
            JUVENILES UNDER THE AGE OF 16 WILL NOT BE PERMITTED ENTRY TO THE GROUND UNLESS ACCOMPANIED BY A PERSON OVER 18 YEARS
          </p>
          <p class="text-sm text-gray-500 mt-2 font-medium">
            AT STAMFORD BRIDGE, THE ENTIRE SHED END AND THE MATTHEW HARDING LOWER TIER ARE SAFE STANDING AREAS.
          </p>
        </div>

      </div>
    `
    this.modalService.createConfirmationModal(title,text).subscribe(result => result.isConfirmed ? this.startTransaction() : false);
  }

  protected cancelTransaction(): void {

    this.checkoutService.cancelTransaction(this.masterSale.id).subscribe({
      next: () => this.authService.getUser(true).then(()=> this.router.navigate([this.checkoutService.returnTo])),
      error:() => this.router.navigate([this.checkoutService.returnTo])
    });

  }

  protected selectDeliveryMethod(key: number): void {
    this.selectedDelivery = this.deliveryMethods[key];
  }

  private checkUserTransactions(type: string): void {
    this.authService.getUser().then(user => {
      if(user){

        // BIND USER        
        this.user = user;

        // CHECK IF HAS TRANSACTIONS OTHERWISE RETURN HOME
        this.checkForEmptyTransactions(user);

        //POPULATE ADRESS DATA
        this.populateAdress(user);

        //SET TRANSACTION
        switch(type){
          
          case 'sale':
            const saleTransactions: SaleTransaction[] = user.sale_transactions;
            this.setTransaction(saleTransactions);
          break;

          case 'membership':
            const membershipTransaction: MembershipTransaction[] = user.membership_transactions;
            this.setTransaction(membershipTransaction);
          break;

          case 'package':
            const packageTransaction: PackageTransaction[] = user.package_transactions;
            this.setTransaction(packageTransaction);
          break;

          case 'reservation':
            const ReservationTransaction: ReservationTransaction[] = user.reservation_transactions;
            this.setTransaction(ReservationTransaction);
          break;

          case 'exchange':
            const exchangeTransaction: SaleTransaction[] = user.exchange_transactions;
            this.setTransaction(exchangeTransaction);
        }
       
      }
    })
  }

  private setTransaction(transaction: SaleTransaction[] | MembershipTransaction[] | PackageTransaction[] | ExchangeTransaction[]): void {
 
    const selectedTransaction = transaction.filter(item => item.id === this.transactionId)[0];
    
    if(!selectedTransaction){
      this.router.navigate(['']);
      return;
    }

    if(selectedTransaction.hasOwnProperty('related_transactions')){
      this.transactions = this.mergeRelatedTransactions(selectedTransaction as SaleTransaction);
      return
    }

    this.transactions = [selectedTransaction];
  }

  private mergeRelatedTransactions(transaction: SaleTransaction): SaleTransaction[] {
    let transactions: SaleTransaction[] = [transaction];

    if(transaction?.related_transactions?.length){
      transaction.related_transactions.map(transaction => transactions.push(transaction));
    }
  
    return transactions;
  }

  private populateAdress(user: User): void {
    this.adressForm.adress      = user.tdc_info.address.address1;
    this.adressForm.city        = user.tdc_info.address.city;
    this.adressForm.countryCode = user.tdc_info.address.address_country_code;
    this.adressForm.countryName = user.tdc_info.address.address_sub_country_name;
    this.adressForm.zipCode     = user.tdc_info.address.postal_code;
  }

  private checkForEmptyTransactions(user: User): void {
   // CHECK HAS EVERYTHING EMPTY
    if(
      !user.sale_transactions.length &&
      !user.exchange_transactions.length &&
      !user.membership_transactions.length &&
      !user.package_transactions.length &&
      !user.reservation_transactions.length
      ) {
        this.router.navigate(['']);
        return;
    }
  }

  private checkSelectedDeliveryMethod(): void {
    if(this.selectedDelivery === undefined){
      this.selectedDelivery = this.deliveryMethods[0];
    }
  }

  private startTransaction(): void {

    // Comprobamos que haya delivery seleccionado, si no por defecto
    this.checkSelectedDeliveryMethod();

    const user:         TdcInfo                  = this.user.tdc_info,
          delivery:     DeliveryMethodsAvailable = this.selectedDelivery,
          isMembership: boolean                  = this.type === 'membership',
          deliveryData: DeliveryMethod = {
            delivery_method_id :    delivery.id,
            delivery_method_type:   delivery.type,
            delivery_first_name:    user.first_name,  
            delivery_last_name:     user.last_name,
            delivery_email:         user.email.email_address,
            delivery_address1:      this.adressForm.adress,
            delivery_address2:      user.address.address2,
            delivery_city:          this.adressForm.city,
            delivery_country_code:  user.address.address_country_code,
            delivery_postal_code:   this.adressForm.zipCode
          }

          
    this.checkoutService.initTransaction(this.masterSale.id, deliveryData).subscribe({
      next:  (v) => {
        
        // Si el precio es 0, no se lanza la pasarela de pago. Actualizamos el usuario y redirigimos a la página de summary. 
        if(this.transactions[0].total_price === 0){
          this.authService.getUser(true).then(()=> this.router.navigate([v.return_url]));
          return;
        }

        this.launchTransactionModal(v);
      },
      error: (e) => console.error(e)
    });

  }

  private launchTransactionModal(response: any): void {

    const modalConfig: SweetAlertOptions = {
      showConfirmButton: false,
      showCancelButton: true,
      buttonsStyling: false,
      cancelButtonText: 'Close',
      customClass:{
        cancelButton: "button w-fit px-2 button-outline dark:border-secondary dark:text-secondary",
      }
    }
    
    this.checkoutModal.swalOptions = modalConfig;

    setTimeout(()=>{
      const component = this.adyenModal.createComponent(AdyenDropInComponent);
        component.instance.sessionId   = response.session_id;
        component.instance.sessionData = response.session_data;
        component.instance.returnUrl   = response.return_url;
        component.instance.start()
      },100)
      
    this.checkoutModal.fire()
  }
}
