<div>
    <p class="text-lg text-main font-medium">Choose your delivery method</p>
    <select #delivery (change)="onSelect(delivery.value)" name="deliveryMethods" id="deliveryMethods" class="mt-3 border rounded-full w-10/12 text-gray-700 font-main py-1 pl-2" style="text-align-last: left;">
        <option *ngFor="let method of methods; index as i" [value]="i">{{method.description}}</option>
    </select>

    <!-- SIMPLE FORM (EMAIL) -->
    <ng-container *ngIf="!postalMode else postalForm">
        <p class="mt-3 text-lg text-main font-medium">Email</p>
        <select name="email" id="customerEmail" class="mt-3 border w-10/12 rounded-full text-gray-800 py-1 pl-2" disabled style="text-align-last: left;">
            <option selected>{{email}}</option>
        </select>
    </ng-container>

    <!-- ADRESS FORM (POSTAL) -->
    <ng-template #postalForm>
        <div class="flex flex-col gap-y-1 mt-2">

            <!-- ADRESS -->
            <label for="adress" class="text-lg text-main font-medium ml-1">Adress</label>
            <input minlength="3" required type="" name="adress" id="adress" autocomplete="street-address" [(ngModel)]="adress.adress" class="mt-1 border font-main w-10/12 rounded-full text-gray-800 py-1 pl-2 active:outline-secondary focus-within:outline-secondary ">
            
            <!-- CITY -->
            <label for="city" class="text-lg text-main font-medium mt-1 ml-1">City</label>
            <input required type="text" name="adress" id="adress" [(ngModel)]="adress.city" class="mt-1 border font-main w-10/12 rounded-full text-gray-800 py-1 pl-2 active:outline-secondary focus-within:outline-secondary ">
           
            <!-- COUNTRYCODES -->
            <label for="countryCode" class="text-lg text-main font-medium mt-1 ml-1">Country Code</label>
            <input type="text" name="Code" id="countryCode" [value]="adress.countryCode" disabled  class="mt-1 border font-main w-10/12 rounded-full text-gray-800 py-1 pl-2 active:outline-secondary focus-within:outline-secondary ">
            <input type="text" name="CCity" id="countryCode" [value]="adress.countryName" disabled  class="mt-1 border font-main w-10/12 rounded-full text-gray-800 py-1 pl-2 active:outline-secondary focus-within:outline-secondary ">
           
            <!-- POSTAL CODE -->
            <label for="zipcode" class="text-lg text-main font-medium mt-1 ml-1">Postal Code</label>
            <input required type="text" name="zipcode" id="zipcode" autocomplete="postal-code" [(ngModel)]="adress.zipCode" class="mt-1 border font-main w-10/12 rounded-full text-gray-800 py-1 pl-2 active:outline-secondary focus-within:outline-secondary ">

        </div>
    </ng-template>
</div>
